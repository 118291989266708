import { Component, Input, SimpleChanges } from '@angular/core';
import { Team, User } from 'src/app/modules/user/models/user.models';
import { UserService } from 'src/app/modules/user/services/user.service';

@Component({
  selector: 'app-team-info',
  templateUrl: './team-info.component.html',
  styleUrls: ['./team-info.component.scss']
})
export class TeamInfoComponent {
  @Input() teamId: string;
  public team : Team;
  public showTeamIcon : boolean = true;
  public users : User[] = [];

  constructor(private userService : UserService,
  ){}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['teamId']) {
      this.getTeamInfo();
    }
  }

  getUser(userId){
    this.userService.getUserInfoCached(userId).subscribe({
      next:(res)=>{
        this.users.push(res);     
      },
      error:(error)=>{
        console.log(error);
        
      }
    })
  }

  getTeamInfo(){
    this.userService.getTeamInfoCached(this.teamId).subscribe({
      next:(res)=>{
        this.team = res;
        this.team.members.forEach((user)=>{
          this.getUser(user);
        })
      },
      error:(error)=>{
        console.log(error);
        
      }
    })
  }
}
