<div class="d-flex align-items-center gap-1" [ngbPopover]="teamInfo" container="body" triggers="mouseenter:mouseleave">
    <i class="ti ti-users fs-3 text-blue" *ngIf="showTeamIcon == true"></i>
    <span>
        {{team?.name}}
    </span>
</div>

<ng-template #teamInfo>
    <div class="divide-y">
        <div  *ngFor="let user of users">
            <div class="d-flex align-items-center gap-1">
                <ngx-avatar size="28" [textSizeRatio]="3" [initialsSize]="3" [name]="user?.displayName"
                    class="my-img">
                </ngx-avatar>
                <div class="text-truncate fs-5">
                    <app-user-info [userId]="user?._id"></app-user-info>
                    <div class="text-muted">
                        {{user?.email?.emailId}}
                    </div>
                </div>
            </div>
            <!-- <div class="row d-flex align-items-center gap-1">
                <div class="col-auto">
                  <ngx-avatar size="30" [textSizeRatio]="3" [initialsSize]="3" [name]="user?.displayName"
                    class="my-img">
                    </ngx-avatar>
                </div>
                <div class="col">
                    <div class="text-truncate fs-5">
                        <app-user-info [userId]="user?._id"></app-user-info>
                    </div>
                </div>
        
            </div> -->
        </div>
        
    </div>

</ng-template>