import { ToastrService } from 'ngx-toastr';
import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HttpResponse,
} from '@angular/common/http';
import { Observable, catchError, map, tap, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/modules/auth/services/auth.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private authService: AuthService,
    private tostr: ToastrService
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        return event;
      }),
      catchError(
        (
          httpErrorResponse: HttpErrorResponse,
          _: Observable<HttpEvent<any>>
        ) => {
          if (
            httpErrorResponse.status === 401 ||
            httpErrorResponse.status === 403
          ) {
            let session = this.authService.getSession();

            if (session) {
              localStorage.clear();
              sessionStorage.clear();
              this.router.navigate(['/login']);
            }
          }
          if (httpErrorResponse.status === 404) {
            console.log(httpErrorResponse.error);
          } else {
            if (httpErrorResponse.error.message) {
              this.tostr.error(httpErrorResponse.error.message);
            } else if (httpErrorResponse.error.data) {
              this.tostr.error(httpErrorResponse.error.data);
            } else {
              console.log(httpErrorResponse.error);

              // this.tostr.error('Server error');
            }
          }
          return throwError(() => httpErrorResponse.error);
        }
      )
    );
  }
}
