import { log } from 'console';
import { BehaviorSubject } from 'rxjs';
import { Team, TeamList, User } from 'src/app/modules/user/models/user.models';

export class SessionUtils {
  static setToSessionStorage(type: string, id: string, data: any): void {
    const key = this.getKey(type, id);
    sessionStorage.setItem(key, JSON.stringify(data));
  }

  static getFromSessionStorage(type: string, id: string): any | null {
    const key = this.getKey(type, id);
    const data = sessionStorage.getItem(key);
    return data ? JSON.parse(data) : null;
  }

  static setBehaviorSubjectToSessionStorage(
    type: string,
    id: string,
    data: any
  ): void {
    const key = this.getKey(type, id);
    const valueToStore =
      data instanceof BehaviorSubject ? data.getValue() : data; // Only store _value if it's BehaviorSubject
    sessionStorage.setItem(key, JSON.stringify(valueToStore));
  }

  static getKey(type: string, id: string): string {
    return `instio.v3.${type}.${id}`;
  }

  static getTeamFromSession(id: string): Team | undefined {
    const teamsArray = sessionStorage.getItem('instio.v3.TEAMS')
      ? JSON.parse(sessionStorage.getItem('instio.v3.TEAMS'))
      : [];
    const team = teamsArray.find((team) => team.id === id);
    return team;
  }

  static getAllTeamsFromSession(): Team[] {
    let teamsArray = sessionStorage.getItem('instio.v3.TEAMS')
      ? JSON.parse(sessionStorage.getItem('instio.v3.TEAMS'))
      : [];
    return teamsArray;
  }

  static setTeamToSession(team: Team) {
    const teamsArray = sessionStorage.getItem('instio.v3.TEAMS')
      ? JSON.parse(sessionStorage.getItem('instio.v3.TEAMS'))
      : [];
    const existingTeam = teamsArray.find((t) => t.id === team.id);
    if (!existingTeam) {
      teamsArray.push(team);
      sessionStorage.setItem('instio.v3.TEAMS', JSON.stringify(teamsArray));
    }
  }

  static setTeamsArrayToSession(teams: Team[]) {
    sessionStorage.setItem('instio.v3.TEAMS', JSON.stringify(teams));
  }

  // static getUsersFromSession(users : User[]){
  //   let usersArray = sessionStorage.getItem('instio.v3.USERS') ? JSON.parse(sessionStorage.getItem('instio.v3.USERS')) : null;
  // }
}
