import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Observable, of } from 'rxjs';
import { BaseFilter } from 'src/app/core/models/common.models';
import { OwnerShipFilters } from 'src/app/modules/budget/models/budget.pnl.models';
import { PnlService } from 'src/app/modules/budget/services/pnl.service';
import {
  Division,
  DivisionsFilter,
} from 'src/app/modules/divisions/models/division.models';
import { DivisionService } from 'src/app/modules/divisions/services/division.service';
import StringUtils from '../../Utils/stringUtils';
import { Frequencies } from 'src/app/modules/budget/models/budget.models';
import { FormControl } from '@angular/forms';
import moment, { Moment } from 'moment';
import { MatDatepicker } from '@angular/material/datepicker';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MomentDateAdapter,
} from '@angular/material-moment-adapter';
import DateUtils from '../../Utils/dateUtils';
export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
export interface FilterOption {
  label: string;
  value: any;
  selected: boolean;
  type: string;
  subOptions?: FilterOption[];
  typeValue: any;
}

export interface DateRangeOption {
  label: string;
  value: string;
  type: 'radio' | 'checkbox';
  selected: boolean;
}
@Component({
  selector: 'app-common-filter',
  templateUrl: './common-filter.component.html',
  styleUrls: ['./common-filter.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class CommonFilterComponent implements OnInit, OnChanges {
  @Input() filterTypes: string[] = [];
  @Input() defaultSelectedFilters: { [key: string]: any[] } = {};
  @Output() filtersChanged = new EventEmitter<{ [key: string]: any[] }>();
  @Input() frequencySelected: Frequencies;
  public dateSelected = new FormControl(moment().subtract(1, 'month'));

  yearWiseOptions: FilterOption[] = [
    {
      label: 'Whole Year',
      value: 'WHOLE_YEAR',
      type: 'radio',
      selected: false,
    } as FilterOption,
  ];

  halfYearOptions: FilterOption[] = [
    {
      label: 'H1',
      value: 'H1',
      type: 'radio',
      selected: false,
    } as FilterOption,
    {
      label: 'H2',
      value: 'H2',
      type: 'radio',
      selected: false,
    } as FilterOption,
  ];

  quarterlyOptions: FilterOption[] = [
    {
      label: 'Q1',
      value: 'Q1',
      type: 'radio',
      selected: false,
    } as FilterOption,
    {
      label: 'Q2',
      value: 'Q2',
      type: 'radio',
      selected: false,
    } as FilterOption,
    {
      label: 'Q3',
      value: 'Q3',
      type: 'radio',
      selected: false,
    } as FilterOption,
    {
      label: 'Q4',
      value: 'Q4',
      type: 'radio',
      selected: false,
    } as FilterOption,
  ];

  public divisionsList: Division[];
  filters: { [key: string]: FilterOption[] } = {};

  constructor(
    private divisionService: DivisionService,
    private pnlService: PnlService
  ) {}
  ngOnChanges(changes: SimpleChanges): void {
    console.log(changes);

    if (changes['defaultSelectedFilters']) {
      this.defaultSelectedFilters =
        changes['defaultSelectedFilters'].currentValue;
      console.log(this.defaultSelectedFilters);
    }
  }

  ngOnInit() {
    this.filterTypes.forEach((type) => {
      this.filterTypes[type] = [];
      // Fetch filter options from the API or predefined values
    });
  }

  getFilterOptions(type: string): Observable<FilterOption[]> {
    // Replace with actual API call or predefined values
    return of([]); // Placeholder
  }

  isCheckedDivision(e) {}
  onDivisionChange(e) {}

  onFilterChange(type: string, option: FilterOption) {
    option.selected = !option.selected;
    let selectedFilters = this.filters[type]
      .filter((options) => options.selected)
      .map((options) => options.value);
    this.filtersChanged.emit({ [type]: selectedFilters });
  }

  onSubFilterChange(type: string, subFilter: string, option: FilterOption) {
    if (subFilter == 'MONTHLY') {
      this.filtersChanged.emit({ MONTHLY: [option.typeValue] });
      return;
    }
    option.selected = !option.selected;
    let selectedFilters = this.filters[type]
      .find((x) => x.value === subFilter)
      .subOptions.filter((options) => options.selected)
      .map((options) => options.value);
    selectedFilters = selectedFilters[selectedFilters.length - 1];
    // let selectedFilters = this.filters[type].filter(options => options.selected).map(options => options.value);
    this.filtersChanged.emit({ [type]: selectedFilters });
  }

  fetchData(type: string) {
    if (!this.filters[type] || this.filters[type]?.length === 0) {
      switch (type) {
        case 'DIVISIONS':
          let filter = new DivisionsFilter();
          this.divisionService.listDivisions(filter).subscribe((data) => {
            this.filters[type] = data.data.map(
              (option) =>
                ({
                  label: option.name,
                  value: option.id,
                  type: 'checkbox',
                  selected:
                    this.defaultSelectedFilters[type]?.includes(option.id) ||
                    false,
                } as FilterOption)
            );
          });
          break;

        case 'LEGAL_NAME':
          let payload = new BaseFilter();
          this.pnlService.getLegalEntities(payload).subscribe((data) => {
            this.filters[type] = data.data.map(
              (option) =>
                ({
                  label: option.name,
                  value: option.id,
                  type: 'checkbox',
                  selected:
                    this.defaultSelectedFilters[type]?.includes(option.id) ||
                    false,
                } as FilterOption)
            );
          });
          break;
        case 'OWNERSHIP_TYPE':
          const ownershipFIlters = OwnerShipFilters;
          this.filters[type] = ownershipFIlters.map(
            (option) =>
              ({
                label: option.label,
                value: option.value,
                type: 'checkbox',
                selected:
                  this.defaultSelectedFilters[type]?.includes(option.value) ||
                  false,
              } as FilterOption)
          );
          break;

        case 'DATE_RANGE':
          this.filters[type] = [
            {
              label: 'Year Wise',
              value: 'YEAR_WISE',
              subOptions: this.yearWiseOptions,
              type: 'option',
            } as FilterOption,
            {
              label: 'Half Year',
              value: 'HALF_YEAR',
              subOptions: this.halfYearOptions,
              type: 'option',
            } as FilterOption,
            {
              label: 'Quarterly',
              value: 'QUARTERLY',
              subOptions: this.quarterlyOptions,
              type: 'option',
            } as FilterOption,
            {
              label: 'Monthly',
              value: 'MONTHLY',
              type: 'datepicker',
              selected: false,
              typeValue: null,
            } as FilterOption,
          ];
          this.filters[type].forEach((filter) => {
            if (this.defaultSelectedFilters[type]?.[0]?.type == filter.value) {
              filter.selected = true;
              filter.typeValue = this.defaultSelectedFilters[type]?.[0]?.value
                ? this.defaultSelectedFilters[type]?.[0]?.value
                : null;
              this.dateSelected.setValue(moment(filter.typeValue));
            } else if (filter.subOptions) {
              filter.subOptions.forEach((subFilter) => {
                if (
                  this.defaultSelectedFilters[type]?.[0]?.type ==
                  subFilter.value
                ) {
                  subFilter.selected = true;
                  filter.typeValue = subFilter.value ? subFilter.value : null;
                }
              });
            }
          });
          break;
      }
    } else {
      console.log(this.defaultSelectedFilters);

      switch (type) {
        case 'DIVISIONS':
          this.filters[type].forEach((filter) => {
            if (this.defaultSelectedFilters[type]?.includes(filter.value)) {
              filter.selected = true;
            } else {
              filter.selected = false;
            }
          });
          break;
        case 'OWNERSHIP_TYPE':
          this.filters[type].forEach((filter) => {
            if (this.defaultSelectedFilters[type]?.includes(filter.value)) {
              filter.selected = true;
            } else {
              filter.selected = false;
            }
          });
          break;
        case 'LEGAL_NAME':
          this.filters[type].forEach((filter) => {
            if (this.defaultSelectedFilters[type]?.includes(filter.value)) {
              filter.selected = true;
            } else {
              filter.selected = false;
            }
          });
          break;
        case 'DATE_RANGE':
          this.filters[type].forEach((filter) => {
            if (this.defaultSelectedFilters[type]?.[0]?.type == filter.value) {
              filter.selected = true;
              filter.typeValue = this.defaultSelectedFilters[type]?.[0]?.value
                ? this.defaultSelectedFilters[type]?.[0]?.value
                : null;
              this.dateSelected.setValue(moment(filter.typeValue));
            }
          });
          break;
      }
    }
  }

  getDisplayString(label) {
    return StringUtils.getDisplayNameString(label);
  }

  chosenYearHandler(normalizedYear: Moment, datepicker: MatDatepicker<Moment>) {
    let ctrlValue = this.dateSelected.value;
    if (!ctrlValue) {
      ctrlValue = moment();
    }
    ctrlValue.year(normalizedYear.year());
    this.dateSelected.setValue(ctrlValue);
  }

  setMonthAndYear(
    normalizedMonthAndYear: Moment,
    datepicker: MatDatepicker<Moment>
  ) {
    // let rangeIndex = this.filterTypes['Date Range']?.findIndex(range=>range.value == 'MONTHLY')
    // this.filterTypes['Date Range'][rangeIndex].selected = true

    const ctrlValue = this.dateSelected.value!;
    // if (!ctrlValue) {
    //   ctrlValue = new moment();
    //   ctrlValue.setDate(1); // Set date to the first of the month
    // }
    ctrlValue.month(normalizedMonthAndYear.month());
    ctrlValue.year(normalizedMonthAndYear.year());
    this.dateSelected.setValue(ctrlValue);
    const dateObject = this.dateSelected.value;

    let year = dateObject.year();
    let month = dateObject.month() + 1;
    console.log('hee', this.filters);

    this.filters['DATE_RANGE'].forEach((element) => {
      if (element.value != 'MONTHLY') {
        element.selected = false;
      } else {
        element.selected = false;
        element.typeValue = year + '-' + DateUtils.formatMonth(month);
        this.onSubFilterChange('DATE_RANGE', element.value, element);
      }
    });
    // this.updateQueryParam({key: 'advancedDateFilterType', value: null});
    // this.updateQueryParam({key: 'monthViewSelected', value:  year+'-'+DateUtils.formatMonth(month)});
    datepicker.close();
  }

  clearDate() {
    console.log('reset');
    this.dateSelected.reset();
    let rangeIndex = this.filters['DATE_RANGE']?.findIndex(
      (range) => range.value == 'MONTHLY'
    );
    this.filters['DATE_RANGE'][rangeIndex].selected = false;
    this.filters['DATE_RANGE'][rangeIndex].typeValue = null;
  }
}
