import { BaseFilter, CommonRequest } from "src/app/core/models/common.models";
import { Status, User } from "../../user/models/user.models";

export class DivisionCreateRequest extends CommonRequest{
    name:string;
    description:string;
    managerId:string;
    id:string;
    financeManagerId : string;
}

export class DivisionsFilter extends BaseFilter{
}

export class Division {
    name: string
    description: string
    managerId: string;
    managerInfo:User
    companyId: string
    id: string;
    addedOn:number;
    status:Status;
    financeManagerId : string;
  }