import { SessionUtils } from './../../../shared/Utils/sessionUtils';
import { HttpClient, HttpContext, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPTY, Observable, catchError, map, of, shareReplay, tap } from 'rxjs';
import { ApiPaginatedResponse, ApiPaginationResponse, ApiResponse } from 'src/app/core/models/common.models';
import { BaseService } from 'src/app/core/services/base.service';
import { DepartmentDropdownRequest, DepartmentDropdownResponse, ListUserRequest, ResetPasswordRequest, Team, TeamList, TeamResponse, User, UserRole, UserSearchRequest } from '../models/user.models';
import { BYPASS_INJECTION } from 'src/app/core/interceptors/common.interceptor';
import { Department, DepartmentName } from '../../department/models/department.models';

@Injectable({
  providedIn: 'root',
})
export class UserService extends BaseService {
  private inProgressRequests: { [userId: string]: Observable<User> } = {};

  constructor(private http: HttpClient) {
    super();
  }

  getUser(userId: string) {
    return this.http.get<ApiResponse<User>>(`${this.gemURL}/user/${userId}`);
  }
  getUserInfo(userId: string) {
    return this.http.get<ApiResponse<User>>(
      `${this.gemURL}/user/info/public?userId=${userId}`
    );
  }

  getUserInfoCached(userId: string) {
    if (this.inProgressRequests[userId]) {
      return this.inProgressRequests[userId];
    }
    let type = 'USER';
    const cachedData = SessionUtils.getFromSessionStorage(type, userId);
    if (cachedData) {
      return of(cachedData);
    }
    const request = this.http
      .get<ApiResponse<User>>(
        `${this.gemURL}/user/info/public?userId=${userId}`
      )
      .pipe(
        tap((response) => {
          response.data.displayName = response.data.lastName
            ? `${response.data.firstName} ${response.data.lastName}`
            : `${response.data.firstName} -`;
          SessionUtils.setToSessionStorage(type, userId, response.data);
        }), // tap remains for side effects
        catchError((error) => {
          console.error(`Error fetching ${type} details: ${error}`);
          delete this.inProgressRequests[userId];
          return EMPTY; // Return an empty observable on error
        }),
        map((response) => response.data),
        shareReplay(1)
      );
    this.inProgressRequests[userId] = request;

    return request;
  }

  //Get all users list
  getUserList(payload: ListUserRequest) {
    return this.http.post<ApiResponse<User[]>>(
      `${this.gemURL}/user/public`,
      payload,
      { context: new HttpContext().set(BYPASS_INJECTION, true) }
    );
  }
  getUserListCompany(companyId?: string) {
    return this.http.post<ApiResponse<User[]>>(
      `${this.gemURL}/user/public`,
      {}
    );
  }

  getFilteredUsersList(payload: ListUserRequest) {
    return this.http.post<ApiResponse<User[]>>(
      `${this.gemURL}/user/dropdown`,
      payload
    );
  }
  //Search users
  searchUsers(request: UserSearchRequest) {
    return this.http.post<ApiPaginationResponse<User[]>>(
      `${this.gemURL}/user/list`,
      request
    );
  }
  getDepartmentMembers(request: any) {
    return this.http.post<any>(`${this.gemURL}/user/dropdown`, request);
  }

  createUser(request: User) {
    return this.http.post<ApiResponse<User>>(`${this.gemURL}/user`, request);
  }

  editUser(request: User) {
    return this.http.put<ApiResponse<User>>(
      `${this.gemURL}/user/profile`,
      request
    );
  }

  getDepartments(request: DepartmentDropdownRequest) {
    return this.http.post<ApiResponse<DepartmentDropdownResponse[]>>(
      `${this.gemURL}/department/dropdown`,
      request
    );
  }

  uploadImage(form: FormData) {
    return this.http.post<ApiResponse<string>>(
      `${this.gemURL}/instio/upload/images`,
      form
    );
  }

  editPermission(request: User) {
    return this.http.put<ApiResponse<string>>(
      `${this.gemURL}/user/permission`,
      request
    );
  }

  deleteUser(user: User) {
    return this.http.delete<ApiResponse<User>>(`${this.gemURL}/user`, {
      body: user,
    });
  }

  blockUser(user: User) {
    return this.http.delete<ApiResponse<User>>(`${this.gemURL}/user/block`, {
      body: user,
    });
  }

  userResetPassword(userId: string, payload: ResetPasswordRequest) {
    return this.http.post<any>(
      `${this.gemURL}/user/password/change/${userId}`,
      payload,
      { context: new HttpContext().set(BYPASS_INJECTION, true) }
    );
  }

  sentInvite(userId: string, channel: any) {
    const params = new HttpParams().set('mode', channel);
    return this.http.get<any>(`${this.gemURL}/user/invite/${userId}`, {
      params: params,
    });
  }

  deleteDevice(deviceId: string, userId: string) {
    return this.http.delete<ApiResponse<User>>(
      `${this.gemURL}/user/device?userId=${userId}&deviceId=${deviceId}`,
      { context: new HttpContext().set(BYPASS_INJECTION, true) }
    );
  }

  updateDevicePermission(status: boolean, userId: string) {
    return this.http.put<any>(
      `${this.gemURL}/user/device?enableDevice=${status}&userId=${userId}`,
      null,
      { context: new HttpContext().set(BYPASS_INJECTION, true) }
    );
  }

  // public
  _getConcernedDepartments() {
    return this.http.get<ApiResponse<DepartmentDropdownResponse[]>>(
      `${this.gemURL}/department/public`
    );
  }

  // team services
  _createTeam(payload: Team) {
    return this.http.post<ApiResponse<TeamResponse>>(
      `${this.gemURL}/team`,
      payload
    );
  }

  _listTeams(payload: BaseReq) {
    return this.http.post<ApiPaginationResponse<TeamList[]>>(
      `${this.gemURL}/team/search`,
      payload
    );
  }

  _getTeam(teamId: string) {
    return this.http.get<ApiResponse<Team>>(
      `${this.gemURL}/team/${teamId}/info`
    );
  }

  _updateTeam(payload: any) {
    return this.http.put<ApiResponse<any>>(`${this.gemURL}/team`, payload);
  }

  _deleteTeam(teamId: string) {
    return this.http.delete<ApiResponse<any>>(`${this.gemURL}/team/${teamId}`, {
      context: new HttpContext().set(BYPASS_INJECTION, true),
    });
  }

  addMember(groupId: string, memberId: string) {
    return this.http.post<ApiResponse<Team>>(
      `${this.gemURL}/team/${groupId}/members/${memberId}`,
      {}
    );
  }

  deleteMember(groupId: string, memberId: string) {
    return this.http.delete<ApiResponse<Team>>(
      `${this.gemURL}/team/${groupId}/members/${memberId}`,
      { context: new HttpContext().set(BYPASS_INJECTION, true) }
    );
  }

  teamsDropdown(payload: BaseReq) {
    return this.http.post<ApiResponse<Team[]>>(
      `${this.gemURL}/team/dropdown`,
      payload
    );
  }

  listUserRoles(department: DepartmentName) {
    return this.http.get<ApiResponse<UserRole[]>>(
      `${this.gemURL}/entity/department/role?department=${department}`
    );
  }

  getTeamInfoCached(teamId: string) {
    const cachedData = SessionUtils.getTeamFromSession(teamId);
    
    if (cachedData) {
      return of(cachedData); // Return cached data if present
    }
  
    // Fetch team info from the API if not cached
    const request = this.http
      .get<ApiResponse<Team>>(`${this.gemURL}/team/${teamId}/info`)
      .pipe(
        tap((response) => {
          SessionUtils.setTeamToSession(response.data); // Cache the response
        }),
        map((response) => response.data), // Extract team data
        catchError((error) => {
          console.error(`Error fetching Team details: ${error}`);
          return EMPTY; // Return an empty observable if an error occurs
        }),
        shareReplay(1) // Cache the result for repeated subscriptions with the same ID
      );
  
    return request;
  }

  getAllTeamsCached(payload: BaseReq) {
    const cachedData = SessionUtils.getAllTeamsFromSession();
    
    if (cachedData.length) { // Check if cached data is available and not empty
      return of(cachedData); // Return cached data as an observable
    }
  
    // Fetch from API and cache the data in session storage
    const request = this.http.post<ApiResponse<Team[]>>(`${this.gemURL}/team/dropdown`,payload)
      .pipe(
        tap((response) => {
          SessionUtils.setTeamsArrayToSession(response.data); // Store the teams in session
        }),
        map((response) => response.data), // Extract the team array
        catchError((error) => {
          console.error(`Error fetching Team details: ${error}`);
          return EMPTY; // Return an empty observable on error
        }),
        shareReplay(1) // Cache the API result for the current observable
      );
  
    return request;
  }

 
}


export class BaseReq {
  companyId: string;
  siteId: string;
  rowsPerPage: number;
  currentPage: number;
}
