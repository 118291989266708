import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from 'src/app/core/services/base.service';
import { Department, ListDepartmentRequest } from '../models/department.models';
import { BYPASS_INJECTION } from 'src/app/core/interceptors/common.interceptor';
import { ApiPaginatedResponse, ApiResponse, PaginatedWebData } from 'src/app/core/models/common.models';

@Injectable({
  providedIn: 'root'
})
export class DepartmentService extends BaseService{

  constructor(private http: HttpClient) { 
    super();
  }
  addDepartment(request: Department) {
    return this.http.post<any>(`${this.gemURL}/department`, request);
  }

  listDepartment(request: ListDepartmentRequest) {
    return this.http.post<ApiResponse<Department[]>>(`${this.gemURL}/department/dropdown`, request);
  }

  updateDepartment(request: Department) {
    return this.http.put<ApiResponse<Department[]>>(`${this.gemURL}/department`, request);
  }

  deleteDepartment(request: Department) {
    return this.http.delete<ApiResponse<Department[]>>(`${this.gemURL}/department`,{body:request});
  }

  getDepartment(departmentId:string) {
    return this.http.get<ApiResponse<Department>>(`${this.gemURL}/department/${departmentId}`);
  }

  filterListDepartment(request: ListDepartmentRequest) {
    return this.http.post<ApiPaginatedResponse< Department[]>>(`${this.gemURL}/department/search`, request);
  }

}
